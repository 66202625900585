import React from "react";
import Marquee from "react-fast-marquee";

const AppMarquee = () => {
  return (
    <div className="p-4 bg-gray-100">
      {/* Marquee for Text */}
      <Marquee speed={100} gradient={false} className="text-lg font-bold text-blue-600">
    <img src="images/pat1.png" alt="logo" className="images"/>
      </Marquee>

      {/* Marquee for Images */}
      <Marquee speed={40} gradient={false} pauseOnHover>
      </Marquee>
    </div>
  );
};

export default AppMarquee;
