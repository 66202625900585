import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import RegistrationForm from './RegistrationForm';
import AppMarquee from "./AppMarquee";
import { Navbar, Nav, NavDropdown, Container, Button } from 'react-bootstrap';
// import './App.css';

const App = () => {
  
  return (
  <>
  <div>{/* Navbar */}
      <Navbar style={{ backgroundColor: 'lightblue' }} variant="light" expand="lg" collapseOnSelect>
        <Container>
          <Navbar.Brand href="#home">This website is undergoing maintenance</Navbar.Brand>
          <Navbar.Toggle aria-controls="navbar-nav" />
          <Navbar.Collapse id="navbar-nav" className="justify-content-end">
            <Nav>
              <Nav.Link href="#home" style={{ color: 'white', fontWeight: 'bold' }}>Home</Nav.Link>
              
              {/* About Dropdown */}
              <NavDropdown title={<span style={{ color: 'white', fontWeight: 'bold' }}>About</span>} id="about-dropdown">
                <NavDropdown.Item LinkContainer to='/ourmission'>Our Mission</NavDropdown.Item>
                <NavDropdown.Item href="#team">Our Team</NavDropdown.Item>
                <NavDropdown.Item href="#history">History</NavDropdown.Item>
                <NavDropdown.Item href="#contact">Contact Us</NavDropdown.Item>
              </NavDropdown>
              
              {/* Services Dropdown */}
              <NavDropdown title={<span style={{ color: 'white', fontWeight: 'bold' }}>Services</span>} id="services-dropdown">

                <NavDropdown.Item href="#web-development">Web Development</NavDropdown.Item>
                <NavDropdown.Item href="#app-development">App Development</NavDropdown.Item>
                <NavDropdown.Item href="#ai-solutions">AI Solutions</NavDropdown.Item>
                <NavDropdown.Item href="#consulting">Consulting</NavDropdown.Item>
              </NavDropdown>
              
              <Nav.Link href="#career" style={{ color: 'white', fontWeight: 'bold' }}>Career</Nav.Link>
              
              {/* Blog Dropdown */}
              <NavDropdown title={<span style={{ color: 'white', fontWeight: 'bold' }}>Blog</span>} id="blog-dropdown">
                <NavDropdown.Item href="#tech-news">Tech News</NavDropdown.Item>
                <NavDropdown.Item href="#case-studies">Case Studies</NavDropdown.Item>
                <NavDropdown.Item href="#tutorials">Tutorials</NavDropdown.Item>
                <NavDropdown.Item href="#industry-trends">Industry Trends</NavDropdown.Item>
              </NavDropdown>
              
              <Nav.Link href="#contact" style={{ color: 'white', fontWeight: 'bold' }}>Contact</Nav.Link>
              
              {/* Sign/Login Button */}
              <Button variant="outline-dark" className="ml-2">Login</Button>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      {/* Routes */}
      </div>
     <img src="images/rit001 1.png" alt="Example" 
     className="example-image" />
     <nav className="bg-indigo-700 border-b border-indigo-500">
      <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
        <div className="flex h-20 items-center justify-between">
          <div
          
  className="flex flex-1 items-center justify-center md:items-stretch md:justify-start">
            <div className="md:ml-auto">
            {/* <a 
              href="https://latest-pavk.onrender.com/"
                 className="text-white bg-grey hover:bg-gray-900 hover:text-white rounded-md px-3 py-2">
                   Continue Reading -
         </a> */}
              <div className="flex space-x-2"></div>
              </div>
            </div>
          </div>
        </div>
      </nav>
      <div className="App">
        <header className="App-header">
        <h2 className="text-2xl text-indigo-500 mb-6 text-center">
           <h1><b><u>Our Partners</u></b></h1> 
            </h2>
          </header>
        </div>
      <AppMarquee /> 
    <section className="py-4">
      <div className="container-xl lg:container m-auto">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 p-4 rounded-lg">
          <div className="bg-black text-white p-6 rounded-lg shadow-md"> 
            <h1 className="text-2xl font-bold"><u>Our Ai Loads</u></h1>
            <p></p>
            <p className="text-2xl mb-4">
            Fintech Ai
            </p>
            <p className="text-2xl mb-4">
            Healthcare Ai
            </p>
            <p className="text-2xl mb-4">
            Engineering Ai
            </p>
            <p className="text-2xl mb-4">
            Security and Rader Ai Surveillance
            </p>
            <a 
            href="https://latest-pavk.onrender.com/"
              className="inline-block bg-indigo text-white rounded-lg px-4 py-2 hover:bg-gray-700">
              Read More
            </a>
          </div>
          <div className="bg-indigo-100 p-6 rounded-lg shadow-md">
            <h2 className="text-2xl font-bold"><u>Leverage Ai </u></h2>
            <p>
            </p>
            <p className="text-2xl mb-4">
            Ai Annotation
            </p>
            <p className="text-2xl mb-4">
            Advanced Annotation
            </p>
            <p className="text-2xl mb-4">
            Ai on SDLC and Machine Learning
            </p>
            <p className="text-2xl mb-4">
            Healtcare AI Data Annotation
            </p>
           <a 
            href="https://latest-pavk.onrender.com/projects"
              className="inline-block bg-indigo-500 text-white rounded-lg px-4 py-2 hover:bg-indigo-600"
            >
              Read More
            </a>
          </div>
        </div>
      </div>
    </section>
  <RegistrationForm />
        
        <img src="images/edutechrittlogo.jpg" alt="logo" className="logo-image" />
        <div className="footer-below">
        <div className="footer-copyright">
          <hr />
          <p>
        {(new Date().getFullYear())} All right reserved . 
          </p><b><p>Contact:- info@edutechritt.com or support@edutechritt.com, Tel: +234 703 129 7351 or +234 802 415 5919</p></b> 
        </div>
        </div>
        
        </>    
  )
}


export default App